/* hover on drag enter */
.hover-left {
  border-radius: 5px 0 0 5px;
  padding: 0 10px;
  height: 100%;
  margin-right: -1px;
  position: relative;
  border-right: 4px solid #75b6fc !important;
}
.hover-right {
  border-radius: 0 5px 5px 0;
  padding: 0 10px;
  height: 100%;
  margin-left: -1px;
  position: relative;
  border-left: 4px solid #75b6fc !important;
}
.p-sortable-column-icon{
  font-size: 12px !important;
}
.p-column-filter-clear-button{
  /*hidden*/
  display: none;
}
.p-paginator{
  padding: 0 !important;
}