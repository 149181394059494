:root {
    @apply light;
}
.light {
    /*Primary colors*/
    --border: rgba(0,0,0,0.1);
    --primary: #1a66e0;
    --secondary: #00b0f3;
    --success: #0055dd;
    /*Text colors*/
    --primary-contrast-color-text: #fff;
    --secondary-contrast-color-text: #000000;
    --success-contrast-color-text: #000000;
    --info-contrast-color-text: #ffffff;
    --warning-contrast-color-text: #000000;
    --danger-contrast-color-text: #000000;
    /*Link colors*/
    --default-link-color: #00b0f3 #00b0f3 #0055DD;
    --primary-link-color: #00ffff #00ffff #00b0f3;
    --secondary-link-color: #00ffff #00ffff #4672b2;
    --success-link-color: #0055dd #0055dd #00b0f3;
    /*fixed colors*/
    --white: #ffffff;
    --black: #000000;
    /*black to rgb*/
    --black-rgb: 0 0 0;
    --info: #0026a4;
    --warning: #ffb700;
    --danger: #FF6700;
    --red: #FF3366;
    --yellow: #F3CA3E;
    --blue: #3399FF;
    --green: #2AC940;
    --pale-yellow: #ffeeba;
    --body-bg: #ffffff;
    --body-color: #000000;
    --border-color: #f2f2f2;
    --top-navbar-bg-default: #1a66e0;
    --dropdown-bg-default: #ffffff;
    --card-bg-default: #ffffff;
    --sidebar-bg-default: #ffffff;
    --nav-tabs-bg-default: #ffffff;
    --surface-container-bg-default: #ffffff;
    --menu-bg-default: #23406b;
    /*menu hover chose darkest color than #23406b*/
    --menu-hover-bg-default: rgba(0,0,0,0.25);
}
.medium {
    /*Primary colors*/
    --primary: #1a66e0;
    --secondary: #4dc7f7;
    --success: #80ffff;
    /*Text colors*/
    --primary-contrast-color-text: #fff;
    --secondary-contrast-color-text: #000000;
    --success-contrast-color-text: #000000;
    --info-contrast-color-text: #ffffff;
    --warning-contrast-color-text: #000000;
    --danger-contrast-color-text: #000000;
    /*Link colors*/
    --default-link-color: #1A66E0 #1A66E0 #4DC7F7;
    --primary-link-color: #80ffff #80ffff #1351aa;
    --secondary-link-color: #1a66e0 #1a66e0 #39a5c6;
    --success-link-color: #1a66e0 #1a66e0 #53bcb9;
    /*fixed colors*/

    --white: #d8d8d8;
    --black: #1e1e1e;
    --info: #2142a3;
    --warning: #ffc433;
    --danger: #cc5200;
    --red: #cc2952;
    --yellow: #C2A333;
    --blue: #297acc;
    --green: #21a133;
    --pale-yellow: #FFF2C7;
    --body-bg: #d8d8d8;
    --body-color: #1e1e1e;
    --border-color: #f2f2f2;
    --top-navbar-bg-default: #1a66e0;
    --dropdown-bg-default: #d8d8d8;
    --card-bg-default: #d8d8d8;
    --sidebar-bg-default: #d8d8d8;
    --nav-tabs-bg-default: #d8d8d8;
    --surface-container-bg-default: #d8d8d8;
    --menu-bg-default: #23406b;
    /*menu hover chose darkest color than #23406b*/
    --menu-hover-bg-default: rgba(0,0,0,0.25);
}
.dark {
    /*Primary colors*/
    --primary: #6699eb;
    --secondary: #99defa;
    --success: #ccffff;
    /*Text colors*/
    --primary-contrast-color-text: #000000;
    --secondary-contrast-color-text: #000000;
    --success-contrast-color-text: #000000;
    --info-contrast-color-text: #ffffff;
    --warning-contrast-color-text: #000000;
    --danger-contrast-color-text: #000000;
    /*Link colors*/
    --default-link-color: #ccffff #ccffff #6699eb;
    --primary-link-color: #ccffff #ccffff #5381c4;
    --secondary-link-color: #6699eb #6699eb #7fc6db;
    --success-link-color:  #6699eb #6699eb #b1e5e3;
    /*fixed colors*/
    --white: #1e1e1e;
    --black: #cccccc;
    --info: #5266a3;
    --warning: #ffd466;
    --danger: #ff8533;
    --red: #ff5c85;
    --yellow: #f5d666;
    --blue: #5cadff;
    --green: #54d466;
    --pale-yellow: #F5D666;
    --body-bg: #1e1e1e;
    --body-color: #cccccc;
    --border-color: #121212;
    --top-navbar-bg-default: rgba(white, 0.07);;
    --dropdown-bg-default: #121212;
    --card-bg-default: #2e2e2e;
    --sidebar-bg-default: #121212;
    --nav-tabs-bg-default: #2E2E2E;
    --surface-container-bg-default: #2e2e2e;
    --menu-bg-default: #23406b;
    /*menu hover chose darkest color than #23406b*/
    --menu-hover-bg-default: rgba(0,0,0,0.25);
}
@import url('fonts/fonts.css');
@tailwind base;
@tailwind components;
@tailwind utilities;
