.p-tree-container {
    height: 100%;
}
.p-treenode-droppoint {
    display: none;
}
.p-treenode-leaf button {
    display: none;
}
.p-treenode-children {
    padding: 0 0 0 0rem !important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler{
    width: 1rem !important;
    height: 1rem !important;
    margin-right: 2px !important;
}
.p-tree-toggler-icon {
    font-size: 12px !important;
}