.filters .p-inputtext{
    padding: 0 !important;
    font-size: 0.7rem !important;
  }
.filters {
    padding: 0 !important;
}
.p-filter-column{
    padding: 0.25rem 0.25rem !important;
}
.p-calendar {
    width: 100% !important;
}