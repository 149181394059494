@import url('https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;600;700&display=swap');

@font-face {
  font-family: "Geogrotesque Bold";
  src: url("./FontsFree-Net-Geogtq-Bd.otf");
}

@font-face {
  font-family: "Geogrotesque Medium";
  src: url("./FontsFree-Net-Geogtq-Md.ttf");
}

@font-face {
  font-family: "Geogrotesque Light";
  src: url("./FontsFree-Net-Geogtq-Lg.ttf");
}

@font-face {
  font-family: "Antartida Light";
  src: url("./AntartidaRounded-Light.ttf");
}

@font-face {
  font-family: "Antartida Medium";
  src: url("./AntartidaRounded-Medium.ttf");
}
@font-face{
  font-family:"SF Display";
  font-weight:700;
  src:url("./SF-UI-Display-Bold.otf");
}
@font-face{
  font-family:"SF Display";
  font-weight:800;
  src:url("./SF-UI-Display-Heavy.otf");
}
@font-face{
  font-family:"SF Display";
  font-weight:500;
  src:url("./SF-UI-Display-Medium.otf");
}
@font-face{
  font-family:"SF Display";
  font-weight:400;
  src:url("./SF-UI-Display-Regular.otf");
}
@font-face{
  font-family:"SF Display";
  font-weight:600;
  src:url("./SF-UI-Display-Semibold.otf");
}
@font-face{
  font-family:"SF Display";
  font-weight:300;
  src:url("./SF-UI-Display-Thin.otf");
}